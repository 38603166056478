import styled from 'styled-components';

export const Container = styled.div`
  height: 70px;
  background: ${props => props.theme.colors.primary};
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: -2px -2px 17px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px -2px 17px 0px rgba(0,0,0,0.75);
  box-shadow: -2px -2px 17px 0px rgba(0,0,0,0.75);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${props => props.theme.colors.text};
    font-size: 1.8rem;
    padding-left: 20px;
    font-weight: bold;
  }

  h1 {
    color: ${props => props.theme.colors.text};
    font-size: 1.2rem;
    padding-left: 20px;
    font-weight: lighter;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;