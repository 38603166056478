import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.footerBackground};
  height: auto;
  padding-bottom: 5px;
  width: 100%;
`;

export const Copyright = styled.div`
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding: 20px 0;
  border-top: 1px solid ${props => props.theme.colors.divider};

  span: last-child {
    font-weight: bold;
    padding-top: 5px;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerRight = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    height: 40px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1400px;
  width: 100%;
`;

// @media (max-width: 768px) {
//   footer .container {
//     flex-direction: column;
//   }

//   footer .right {
//     display: none !important;
//   }
// }

// footer .container .left {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

// footer .MuiIconButton-root {
//   color: #444 !important;
// }

// footer .container .right {
//   font-size: 0.9rem;
//   display: flex;
//   flex-direction: column;
// }

// footer .container .right span:first-child {
//   font-weight: bold;
//   margin-left: 32px;
// }

// footer .container .right span {
//   display: flex;
//   height: 40px;
// }

// footer .container .right a {
//   text-decoration: none;
//   color: #000;
// }

// footer .container .right .icon {
//   color: #666 !important;
// }

// footer .footer-bottom {
//   width: 100%;
//   font-size: 0.8rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-top: 10px;
//   padding: 20px 0;
//   border-top: 1px solid #ccc;
// }

// footer .footer-bottom span:last-child {
//   font-weight: bold;
// }
